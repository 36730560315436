import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"

const NotFoundPage = () => {

	return (
		<Layout>
			<SEO title="404" />
			<h1>404 Not Found</h1>
		</Layout>
	)
}

export default NotFoundPage
